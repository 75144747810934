import React, { Component } from 'react';
import Style from './Style.module.css'
import PhoneImg from '../Image/phoneImg.png'

class Contact extends Component {
    render() {
        return (
            <div className={Style.aboutMeRoot}>
                <div>
                    <div className={Style.contactInfoName}>Laxman</div>
                    <div className={Style.contactInfo2}> Call me : <span style={{fontStyle: "italic"}}>+91-9791478820</span></div>
                    <div className={Style.contactInfo2}> Mail me  : <span style={{fontStyle: "italic"}}>laxmanpatna@gmail.com</span></div>
                </div>
                <div className={Style.contactMeForm}> 
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScT58GDT1c4i3e-4WcLG5NylUVv5wZCzmol5mDKRwTviFqrRQ/viewform?embedded=true" className={Style.formFrameWidth} height="340" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
                <div style={{margin:"10px"}}> 
                    <div className={Style.contactInfoName}>Social Media</div>
                    <div style={{marginLeft:"30px"}} class="fb-page" data-href="https://www.facebook.com/a.look.of.laxman/" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/a.look.of.laxman/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/a.look.of.laxman/">A look of Laxman</a></blockquote></div>
                    <span className={Style.twitter_ovverride}>
                        <a class="twitter-timeline" data-width="340" data-height="500" data-theme="light" href="https://twitter.com/laxmanpatna1?ref_src=twsrc%5Etfw">Tweets by @laxmanpatna1</a> 
                    </span>
                </div>
            </div>
        );
    }
}

export default Contact;