import React from 'react';
// import logo from './logo.svg';
import './App.css';
import MainComp from './comp/MainComp';

function App() {

  return (
    // <div className="App">
    //   {/* <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Hello World
    //     </p>
    //   </header> */}

    //   {/* <div> Hello worlds </div> */}
    //   {/* <Jai></Jai> */}
    //   {/* <Hello></Hello> */}
    //   {/* <Jai/> */}
    //   <LeftSide></LeftSide>
    // </div>
    <div>
      <MainComp></MainComp>
    </div>
  );
}

export default App;
