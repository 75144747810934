import React from 'react';
import Style from './Style.module.css'
import AboutMe from './aboutMe'
import {Link} from 'react-router-dom'

function MenueList(props) {
    return (   
    <div className={Style.tabHolder}>
        
            <Link onClick ={props.toggle_handler} className={Style.tab} to={props.menueObj.uri}> {props.menueObj.name}</Link>

    </div> )
}

export default MenueList;