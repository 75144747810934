import React,{ Component } from "react";
import Tabletop from 'tabletop';

class ContentLoader extends Component {

    constructor(){
        super();
        this.state = {
            data :[]
        }
    }

    componentDidMount(){
        // console.log(this.props.key)
        // debugger;
        Tabletop.init({
            // key : 'https://docs.google.com/spreadsheets/d/132wrAlB-44zUno_E2HyVuHawqE9qd6MFAkvVbVqlvxs/edit?usp=sharing',
            // key : 'https://docs.google.com/spreadsheets/d/17B1cFHQdMTqa3QjctDiPsGbKC8SfEUwJFSoByUYcLRg/edit?usp=sharing',
            key: this.props.dataUrl,
            callback: googleData => {
               this.setState({
                data: googleData
               })
               this.sendData();
              },
              simpleSheet: true
        })
    }

    sendData() {
        this.props.callBack(this.state.data);
    }

    render(){
        return("");
    }
}

export default ContentLoader;