import React, { Component } from 'react';
import Logo from './logo'
import MenueList from './MenueList';
import Style from './Style.module.css'
import { Link } from 'react-router-dom'

class LeftSideBar extends Component {

    constructor() {
        super();
        this.state = {
            data: [{ name: 'About Me', uri: '/about' }, { name: 'Contact', uri: '/contact' }, { name: 'Blog', uri: '/blogs' }, { name: 'Poetry', uri: '/poetries' }]
        }
    }

    render() {
        return (
            <div className={this.props.cssProp}>
                <div className={Style.sidenav}>
                    <div className={Style.mLMenue}>
                        <div>
                            <Link to="/">
                                <div className={Style.logoColor} onClick={this.props.toggle_handler}>
                                    <Logo />
                                </div>
                            </Link>
                        </div>
                        <div className={Style.mTMenue}>
                            {
                                this.state.data.map(menue => (
                                    <MenueList key={menue.uri} menueObj={menue} toggle_handler={this.props.toggle_handler}></MenueList>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className={Style.autorization}> Developed By @ Laxman </div>
            </div>
        );
    }
}

export default LeftSideBar;
