import React, { Component } from 'react';
import Home from './Home'
// import Jai from './comps/kumar'
import AboutMe from './aboutMe'
import ListComponent from './ListComponent'
import Style from './Style.module.css'
import SideNavButton from './sideNavButton'
import ListEleContentHolder from './ListEleContentHolder'
import ContentLoader from './ContentLoader'
import BloagListContentHolder from './BloagListContentHolder'
import BlogListComponent from './BlogListComponent'
import Contact from './Contact'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
} from 'react-router-dom'
  

class MainComp extends Component {

    constructor(){
     super();
     this.state = {
        poetryData : [],
        poetryDataUrl : 'https://docs.google.com/spreadsheets/d/1wsOp7vS7qYsD7eChADBqrgFWDX8oGTfUzjCJ-mJhXds/edit?usp=sharing',
        bloagData : [],
        bloagDataUrl : 'https://docs.google.com/spreadsheets/d/1FOZpjksCRBzZyaIfNGuJSDmCHCAyPK2Qmax-FDz1Uoc/edit?usp=sharing',
        // poetryList : []
     } 
     
     this.addPoetryData = this.addPoetryData.bind(this);
     this.addBlogData = this.addBlogData.bind(this);
    }

    addPoetryData(dataFetched){
        this.setState({
            poetryData: dataFetched
        })
        debugger;
    }

    addBlogData(dataFetched){
        this.setState({
            bloagData: dataFetched
        })
        debugger;
    }

    render() {
        return (
            <div className="App-backGroud">
                {/* Load Quotes Data */}
                <ContentLoader callBack = {this.addPoetryData} dataUrl={this.state.poetryDataUrl}/>
                <ContentLoader callBack = {this.addBlogData} dataUrl={this.state.bloagDataUrl}/>
                {/* <BloagLoader callBack = {this.addBlogData} dataUrl={this.state.bloagDataUrl}/> */}
                <Router>
                    <SideNavButton></SideNavButton>
                    <div className={Style.mainBody}>

                        <Switch>
                        <Route exact path="/" component={withRouter(Home)} /* render={(routerProps)=>(<Home {...routerProps} currentData={quotesData}></Home>)}*//>
                        <Route path="/about" component={withRouter(AboutMe)} />
                        <Route path="/contact"><Contact></Contact></Route>
                        <Route path="/blogs" render={(routerProps)=>(<BlogListComponent {...routerProps} listData={this.state.bloagData} urlCode="blog"></BlogListComponent>)}></Route>
                        <Route path="/blog/:sectionName" component={BloagListContentHolder}></Route>
                        <Route path="/poetries" render={(routerPropss)=>(<ListComponent {...routerPropss} listData={this.state.poetryData} urlCode="poetry"></ListComponent>)}/>
                        <Route path="/poetry/:sectionName" render={(routerProps)=>(<ListEleContentHolder {...routerProps} urlCode="poetry"></ListEleContentHolder>)}></Route>
                        {/* <Route path="poetry/:sectionName"><h1>nonfsafo</h1></Route> */}
                        </Switch>

                        {/* <AboutMe>HI</AboutMe> */}
                    </div>
                </Router>
            </div>
        );
    }
}

export default MainComp;