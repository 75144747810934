import React, { Component } from 'react';
import LIstElementComp from './LIstElementComp'
import AboutMe from './aboutMe'
import { Link, useRouteMatch, useParams, Switch, Route, Router } from 'react-router-dom'
import img4 from '../Image/L2.jpg'

import Style from './Style.module.css'

class BlogListComponent extends Component {

    constructor(){
        super();
        this.state = {
            listData:[]
        }
    }

    componentDidMount(){
        this.setState({
            listData:this.props.listData 
        })
    }
    

    render() {
        return (    
            <div className={Style.aboutMeRoot}>
                {
                    this.state.listData.map((each,index) => (
                        <Link style={{textDecoration: 'none' }} to={{
                            pathname : `${this.props.urlCode}/${index}`,
                            state : each
                        }} >
                            <LIstElementComp heading={each.Heading} Description={each.Description} imageUrl={each.imageUrl}/>
                        </Link>
                    ))
                }
                
                {/* <Link style={{textDecoration: 'none' }} to="/post/second" >
                    <LIstElementComp />
                </Link> */}
            </div>
        );
    }
}

export default BlogListComponent;