import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Style from './Style.module.css'
import HeadingBg from '../Image/HeadingBg.gif'

class ListEleContentHolder extends Component {

    actualContaint(contains){
        let content = "";
        let j = 1;
        let i = contains["P"+j];
        
        while(i){
            content += "<p>"+i+"</p>";
            j++;
            i = contains["P"+j];
        }
        return content;
    }
    
    render() {
        const containts = this.props.location.state;
        const actualC = this.actualContaint(containts);
        return (
            <div className={`${Style.aboutMeRoot}, ${Style.mL15}`}>
                <div>
                    <div className={Style.cHFlex}>
                        <h1 className={Style.contentHoderHeading}>{containts.Heading}</h1>
                    </div>
                    <img src={HeadingBg} className={Style.contentHoderHeadingBg}></img>
                    <div className={Style.contentHolder}>
                        <div className={Style.headinMeta}>
                            <div id="createdOnCH"> Created On : {containts.CreatedOn}</div>
                            <div id="createdByCH"> Created By : {containts.CreatedBy}</div>
                        </div>

                        <div className={Style.mainContent}>
                            {ReactHtmlParser(actualC)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListEleContentHolder;