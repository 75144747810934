import React, { Component } from 'react';
import Style from './Style.module.css'

class logo extends Component {
    render() {
        return (
            <div>
                <div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>L</div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>A</div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>X</div>
                </div>
                <div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>M</div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>A</div>
                    <div className={`${Style.iB} ${Style.logoBlock}`}>N</div>
                </div>
            </div>
        );
    }
}

export default logo;