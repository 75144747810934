import React, { Component } from 'react';
import aboutImg from '../Image/aboutImg.png'
import Style from './Style.module.css'

class aboutMe extends Component {
    render() {
        return (
            <div className={Style.aboutMeRoot}>
                <div className={Style.aboutMeImageIntro}>
                    <img src={aboutImg} className={Style.aboutMeImg}/> 
                    <h2>Laxman</h2>
                </div>
                <div className={Style.aboutMeText}>
                    <p className={Style.aboutMeFirstPara}>
                    Hi, I’m Laxman, a full stack software developer, writer and a non professional dancer.

I started this website as a place to document everything I learned while going through a career change. I learn in public and write about everything I know.

I aim to create a beautiful corner of the web free of ads, sponsored posts, newsletter pop-ups, affiliate links, and the rest of the annoying noise we're so accustomed to seeing on the internet these days.
                    </p>
                    
                
                </div>
            </div>
        );
    }
}

export default aboutMe;