import React, { Component } from 'react';
import ContentLoader from './ContentLoader'
import { Zoom } from 'react-slideshow-image'
import './slider.css'
import img1 from '../Image/slide1.jpg'
import img2 from '../Image/slide2.jpg'
import img3 from '../Image/slide3.jpg'
import img4 from '../Image/slide4.jpg'
import img5 from '../Image/slide5.jpg'
import img6 from '../Image/slide6.jpg'
import img7 from '../Image/slide7.jpg'

class Home extends Component {

  constructor(){
    super();
    this.state = {
        data :[],
        isChanged : true,
        key : 'https://docs.google.com/spreadsheets/d/17B1cFHQdMTqa3QjctDiPsGbKC8SfEUwJFSoByUYcLRg/edit?usp=sharing'
    }
    this.getData = this.getData.bind(this);
    this.removeAnimationClass = this.removeAnimationClass.bind(this);
  }

  images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7
  ];

  changeColorType =[
    2,
    1,
    5,
    4
  ]
 
  zoomOutProperties = {
    duration: 10000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
       this.setState({
        isChanged : true
        })
        setTimeout(this.removeAnimationClass, 9999);
    }
 }

  removeAnimationClass(){
    this.setState({
      isChanged : false
    })
  }
   
   getData(dataFetchec){
     this.setState({
      data: dataFetchec
     })
   }
 
   textValue = (index) =>{
       if( this.state.data.length > 0 ){
         return <h1 className={`${'sliderText'} ${this.state.isChanged >0 ? "sliderAnimation" : ""} ${this.changeColorType.includes(index) ? "slideTextColor" : ""}`}>{this.state.data[index].quotes}</h1>;
       }
   }


  render() {
    return (
      <div className="slide-container">
        <ContentLoader callBack = {this.getData} dataUrl={this.state.key}/>
        <Zoom {...this.zoomOutProperties}>
          {
            this.images.map((each, index) =>(<div  key={index}  className="width100">
              {this.textValue(index)}
              <img className="homeImage" src={each} />
            </div>) )
          }
        </Zoom>
      </div>
    );
  }
}

export default Home;