import React, { Component } from 'react';
import LeftSide from './LeftSideBar'
import Style from './Style.module.css'
import { FaAlignJustify } from 'react-icons/fa'
import { FaHome } from 'react-icons/fa'
import { FaAlignLeft} from 'react-icons/fa'
import { Link } from 'react-router-dom'

class sideNavButton extends Component {

    constructor(){
        super();
    }
    state = {
        toggle:false
    }
    Toggle = () => {
        if( window.innerWidth < 1200 )
        this.setState({toggle:!this.state.toggle})
        
    }
    componentWillMount(){
        // alert("will mount");
        if( window.innerWidth < 1200 ){
            this.setState({toggle:true});
        }
    }
    render() {
        const isToggled = this.state.toggle;
        let toggleButton = isToggled ? <FaAlignJustify size={25}/> : <FaAlignLeft size={25}/>;
        return (
            <div>
                
                <div className={Style.sideNavButton}>
                    <button onClick={this.Toggle}>
                            {toggleButton}
                    </button>
                    <Link to="/">
                        <FaHome size = {30} style={{color:'black', float : "right"}}></FaHome>
                    </Link>
                </div>

                <LeftSide cssProp={this.state.toggle ? Style.notDisplay : Style.display} toggle_handler={this.Toggle}></LeftSide>
            </div>
        );
    }
}

export default sideNavButton;