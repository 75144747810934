import React, { Component } from 'react';
import img4 from '../Image/slide4.jpg'
import Style from './Style.module.css'

class LIstElementComp extends Component {

    constructor(){
        super();
    }

    render() {
        return (
            <div className={Style.listElementBody}>
                <div>
                    <img className={Style.listElemetImage} src={this.props.imageUrl} />
                </div>
                <div className={Style.m10}>
                    <div className={Style.listElemetHeading}> 
                        <span className={Style.style_Ellipsis}>
                            {this.props.heading}
                        </span>
                    </div>
                    <div className={Style.listElemetDesc}> 
                        <span className={Style.style_Ellipsis}>
                            {this.props.Description}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default LIstElementComp;